import { Center, Heading } from "@efishery/onefish";
import { useEffect } from "react";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { useParams } from "react-router-dom";
import { FLAGGING_SERVICE } from "src/configs/featuresFlag";
import { SERVICE } from "src/configs/service";
import { useOne } from "src/hooks";
import { IUseOneReturnValue } from "src/hooks/dataProvider/type";
import { useFlagging } from "src/hooks/flagging";
import { IPromoData, IPromoDataObject } from "src/interface/promo.model";
import { BaseKey } from "src/libs/dataProvider/types";

import { MobileLayout } from "../../../../components";
import { IPromoKuDetailPageProps, PromoDetail } from "../..";
import { trackOnUsePromo, trackPromoKuDetailsLoaded } from "./tracker";

export default function PromoKuDetails({
  headerProps,
  footerProps,
  chakra,
  useOuterContainer,
  resourceProps,
  paramsId,
  recordId,
  buttonProps,
  onUsePromo,
}: IPromoKuDetailPageProps) {
  const params = useParams();
  const id = recordId ?? params[paramsId ?? "id"];
  const { data: flaggingData } = useFlagging([
    {
      flag: FLAGGING_SERVICE.FEATURE_PROMO_REVAMP_V2,
      defaultValue: { variantKey: "off" },
    },
  ]);

  const queryResults = useOne<IPromoDataObject>({
    apiUrl: SERVICE.PROMO_V2,
    resource: "v1/promos",
    id: id as BaseKey,
    meta: {
      ...resourceProps?.meta,
    },
  });

  const { data, isLoading } =
    queryResults as IUseOneReturnValue<IPromoDataObject>;
  const promoData = data?.data?.data as IPromoData;

  if (resourceProps?.gtmID) {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: resourceProps?.gtmID || "",
    };
    TagManager.initialize(tagManagerArgs);
  }

  useEffect(() => {
    if (resourceProps?.gtmID && !isLoading) {
      trackPromoKuDetailsLoaded(
        promoData.code,
        resourceProps?.meta?.headers?.Authorization || ""
      );
    }
  }, [isLoading, resourceProps?.gtmID]);

  if (!id) {
    return (
      <MobileLayout
        headerProps={headerProps}
        footerProps={footerProps}
        chakra={chakra?.layout}
        useOuterContainer={useOuterContainer}
      >
        <Center marginTop="60px" padding="40px">
          <Heading size="sm" textAlign="justify">
            [Promo ID] is required. Make sure to provide [recordId] property
            with correct value
          </Heading>
        </Center>
      </MobileLayout>
    );
  }

  return (
    <MobileLayout
      headerProps={headerProps}
      footerProps={footerProps}
      chakra={chakra?.layout}
      useOuterContainer={useOuterContainer}
    >
      <PromoDetail
        promoData={promoData}
        isLoading={isLoading}
        chakra={chakra?.promokuDetail}
        buttonProps={buttonProps}
        flaggingData={flaggingData}
        onUsePromo={(promoData: IPromoData) => {
          if (onUsePromo) {
            if (resourceProps?.gtmID) {
              trackOnUsePromo(
                promoData.code,
                resourceProps?.meta?.headers?.Authorization || ""
              );
            }
            onUsePromo(promoData);
          }
        }}
      />
    </MobileLayout>
  );
}
